import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Button } from 'react-admin';
import { useAutomationContext } from '../../../common/AutomationContextProvider';

interface AudioFileUploaderProps {
  open: boolean;
  setClose: () => void;
  youtubeId: string;
  start: number | null;
  end: number | null;
}

const AudioFileUploader: React.FC<AudioFileUploaderProps> = ({
  open,
  setClose,
  youtubeId,
  start,
  end,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const {
    // SONIX RELATED
    // sonixId,
    // setSonixId,
    // sonixPublicUrl,
    // setSonixPublicUrl,
    // TIME RELATED
    // startTimeParsed,
    // setStartTimeParsed,
    // endTimeParsed,
    // setEndTimeParsed,
    // SNACKBAR RELATED
    showSnackbar,
    // TRANSCRIPT RELATED
    // enTranscript,
    // setEnTranscript,
    // isTranscriptionInProgress,
    // setIsTranscriptionInProgress,
    transcribe,
  } = useAutomationContext();

  const handleFileChange = (file: File | null): void => {
    setFile(file);
  };

  const handleUpload = useCallback(
    async (force?: boolean): Promise<void> => {
      if (!file) {
        showSnackbar({
          message: 'No file provided!',
          severity: 'error',
        });

        return;
      }
      if (!youtubeId) {
        showSnackbar({
          message: 'No YouTube video ID provided!',
          severity: 'error',
        });

        return;
      }
      if (start == null) {
        showSnackbar({
          message: 'No start time provided!',
          severity: 'error',
        });

        return;
      }
      if (end === undefined || end === null) {
        showSnackbar({
          message: 'No end time provided!',
          severity: 'error',
        });
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('youtubeId', youtubeId);
      formData.append('start', start.toString());
      formData.append('end', end.toString());
      if (force) formData.append('force', force ? 'true' : 'false');

      setClose();

      await transcribe({
        formData,
        youtubeId,
        start,
        end,
        force,
      });
    },
    [file, youtubeId, start, end, setClose, transcribe, showSnackbar],
  );

  return (
    <>
      <Dialog open={open} onClose={setClose}>
        <DialogTitle>Upload Audio File</DialogTitle>
        <DialogContent dividers>
          <p>
            You can upload an .mp3 or .mp4 file here to submit it for
            transcription. You can use popular YouTube download tools to
            download the audio/video file. The file that&apos;s uploaded here
            should be the same length as the YouTube video. You only need the
            file when you first upload.
          </p>

          <p>
            When you come back later to check on the progress/result, you can
            simply click on the TRANSCRIBE button. As long as the YouTube Video
            ID, start and end times are identical, the program will
            automatically find the correct transcript!
          </p>
          <input
            type="file"
            accept=".mp3,.mp4"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              handleFileChange(e.target.files?.[0] || null);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            label="Force Upload"
            style={{ backgroundColor: '#f44336' }}
            onClick={(): void => {
              handleUpload(true);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            label="Upload"
            onClick={handleUpload}
          />
          <Button
            variant="contained"
            color="primary"
            label="Close"
            onClick={setClose}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

AudioFileUploader.propTypes = {
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  youtubeId: PropTypes.string.isRequired,
  start: PropTypes.number,
  end: PropTypes.number,
};

export default AudioFileUploader;
