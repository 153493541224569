import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { CONTENT_AUTOMATION_API_URL } from '../constants';

const useProviderValue = () => {
  // //////////////////////////// SNACKBAR RELATED
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('info');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const showSnackbar = ({
    message,
    severity = 'info',
  }: {
    message: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
  }): void => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setIsSnackbarOpen(true);
  };

  const closeSnackbar = (
    event: React.SyntheticEvent<any, Event>,
    reason?: string,
  ): void => {
    if (reason === `clickaway`) {
      return;
    }

    setIsSnackbarOpen(false);
  };
  // //////////////////////////// SNACKBAR RELATED END

  // //////////////////////////// TRANSCRIPTION RELATED
  const [sonixId, setSonixId] = useState('');
  const [sonixPublicUrl, setSonixPublicUrl] = useState('');
  const [startTimeParsed, setStartTimeParsed] = useState(0);
  const [endTimeParsed, setEndTimeParsed] = useState(0);
  const [enTranscript, setEnTranscript] = useState(``);
  const [isTranscriptionInProgress, setIsTranscriptionInProgress] =
    useState(false);

  const transcribe = useCallback(
    async ({
      youtubeId,
      start,
      end,
      formData,
      force,
    }: {
      youtubeId: string;
      start: number;
      end: number;
      formData?: FormData;
      force?: boolean;
    }) => {
      if (!youtubeId) {
        showSnackbar({
          message: 'No YouTube video ID provided!',
          severity: 'error',
        });
        return;
      }
      if (start === undefined || start === null) {
        showSnackbar({
          message: 'No start time provided!',
          severity: 'error',
        });

        return;
      }
      if (end === undefined || end === null) {
        showSnackbar({
          message: 'No end time provided!',
          severity: 'error',
        });
        return;
      }

      setStartTimeParsed(start);
      setEndTimeParsed(end);

      showSnackbar({
        message: 'Transcribing...',
        severity: 'info',
      });

      setIsTranscriptionInProgress(true);

      const url = `${CONTENT_AUTOMATION_API_URL}/transcribe`;

      const response = formData
        ? await fetch(url, {
            method: 'POST',
            body: formData,
          })
        : await fetch(
            `${url}/${youtubeId}?start=${start}&end=${end}${
              force ? `&force=true` : ``
            }`,
          );
      const results = await response.json();

      if (!results.success) {
        showSnackbar({
          message: results.errorMessage,
          severity: 'error',
        });
        setIsTranscriptionInProgress(false);
        return;
      }
      // if results.success is true, then results.status will be `transcribing` or `completed`.
      let successMessage = `Transcription started!`;
      if (results.status === `transcribing` || results.status === `preparing`) {
        successMessage = 'Transcription in progress. Check back later.';
      }
      if (results.status === `completed`) {
        successMessage = 'Transcription completed!';
        setEnTranscript(results.transcript);
      }
      showSnackbar({
        message: successMessage,
        severity: 'success',
      });
      setIsTranscriptionInProgress(false);
      setSonixId(results.sonixItem.id);
      setSonixPublicUrl(results.sonixItem.public_url);
    },
    [],
  );
  // //////////////////////////// TRANSCRIPTION RELATED END
  return {
    // SONIX RELATED
    sonixId,
    setSonixId,
    sonixPublicUrl,
    setSonixPublicUrl,
    // TIME RELATED
    startTimeParsed,
    setStartTimeParsed,
    endTimeParsed,
    setEndTimeParsed,
    // SNACKBAR RELATED
    showSnackbar,
    // SNACKBAR INTERNAL
    isSnackbarOpen,
    closeSnackbar,
    snackbarMessage,
    snackbarSeverity,
    // TRANSCRIPT RELATED
    enTranscript,
    setEnTranscript,
    isTranscriptionInProgress,
    setIsTranscriptionInProgress,
    transcribe,
  };
};

const AutomationContext = createContext<
  ReturnType<typeof useProviderValue> | undefined
>(undefined);
export const AutomationContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const providerValue = useProviderValue();
  return (
    <AutomationContext.Provider value={providerValue}>
      {children}
      <Snackbar
        open={providerValue.isSnackbarOpen}
        autoHideDuration={3000}
        onClose={providerValue.closeSnackbar}
        message={providerValue.snackbarMessage}
      >
        <Alert
          onClose={providerValue.closeSnackbar}
          severity={providerValue.snackbarSeverity}
        >
          {`[Transcription Helper] ${providerValue.snackbarMessage}`}
        </Alert>
      </Snackbar>
    </AutomationContext.Provider>
  );
};
AutomationContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useAutomationContext = (): ReturnType<typeof useProviderValue> => {
  const context = useContext(AutomationContext);
  if (context === undefined) {
    throw new Error(
      `useAutomationContext must be used within a AutomationContextProvider`,
    );
  }
  return context;
};
