import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Button } from 'react-admin';
import expandableSelectStyles from './ExpandableSelectStyles';
import { CONTENT_AUTOMATION_API_URL } from '../../../constants';
import { useAutomationContext } from '../../../common/AutomationContextProvider';
import AudioFileUploader from './AudioFileUploader';

const useStyles = expandableSelectStyles;

const fetchUrl = CONTENT_AUTOMATION_API_URL;

const convertTimeIntoSeconds = (time, roundUp = false) => {
  // time is in the format of hh:mm:ss,mmm. Throw away the milliseconds if roundUp is false.
  const timeArray = time.split(':');
  const hours = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);
  const seconds = parseInt(timeArray[2].split(`,`)[0], 10);
  const milliseconds = parseInt(timeArray[2].split(`,`)[1], 10);

  return roundUp && milliseconds > 0
    ? hours * 3600 + minutes * 60 + seconds + 1
    : hours * 3600 + minutes * 60 + seconds;
};

const AutoTranscriptionHelper = ({ youTubeVideoId, startTime, endTime }) => {
  const classes = useStyles();

  const {
    // SONIX RELATED
    sonixId,
    // setSonixId,
    sonixPublicUrl,
    // setSonixPublicUrl,
    // TIME RELATED
    // startTimeParsed,
    // setStartTimeParsed,
    // endTimeParsed,
    // setEndTimeParsed,
    // SNACKBAR RELATED
    showSnackbar,
    // TRANSCRIPT RELATED
    enTranscript,
    setEnTranscript,
    isTranscriptionInProgress,
    // setIsTranscriptionInProgress,
    transcribe,
  } = useAutomationContext();

  // DIALOG RELATED STATES
  const [
    isForceTranscriptionConfirmDialogOpen,
    setIsForceTranscriptionConfirmDialogOpen,
  ] = useState(false);
  const [isAudioFileUploaderDialogOpen, setIsAudioFileUploaderDialogOpen] =
    useState(false);

  // TRANSLATION RELATED STATES
  const [koTranscript, setKoTranscript] = useState(``);
  const [jaTranscript, setJaTranscript] = useState(``);
  const [areTranslatedTranscriptsEdited, setAreTranslatedTranscriptsEdited] =
    useState(false);
  const [isTranslationInProgress, setIsTranslationInProgress] = useState(false);

  const transcribeOnClickHandler = useCallback(
    async (force) => {
      if (!youTubeVideoId) {
        showSnackbar({
          message: 'No YouTube video ID provided!',
          severity: 'error',
        });
        return;
      }
      if (!startTime) {
        showSnackbar({
          message: 'No start time provided!',
          severity: 'error',
        });
        return;
      }
      if (!endTime) {
        showSnackbar({
          message: 'No end time provided!',
          severity: 'error',
        });
        return;
      }

      const start = convertTimeIntoSeconds(startTime);
      const end = convertTimeIntoSeconds(endTime, true);

      await transcribe({
        youtubeId: youTubeVideoId,
        start,
        end,
        force,
      });
    },
    [transcribe, youTubeVideoId, startTime, endTime, showSnackbar],
  );

  const updateEnTranscriptOnClickHandler = useCallback(async () => {
    if (!sonixPublicUrl) {
      showSnackbar({
        message: 'No Sonix public URL provided!',
        severity: 'error',
      });

      return;
    }
    window.open(sonixPublicUrl, '_blank');
  }, [sonixPublicUrl, showSnackbar]);

  const syncEnTranscriptOnClickHandler = useCallback(async () => {
    if (!sonixId) {
      showSnackbar({
        message: 'No Sonix ID provided!',
        severity: 'error',
      });

      return;
    }

    showSnackbar({
      message: 'Syncing transcript...',
      severity: 'info',
    });

    const response = await fetch(`${fetchUrl}/transcribe/sync/${sonixId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const results = await response.json();

    if (!results.success) {
      showSnackbar({
        message: results.errorMessage,
        severity: 'error',
      });

      return;
    }

    showSnackbar({
      message: 'Transcript synced!',
      severity: 'success',
    });

    setEnTranscript(results.transcript);
  }, [sonixId, showSnackbar, setEnTranscript]);

  const translateOnClickHandler = useCallback(async () => {
    if (!sonixId) {
      showSnackbar({
        message: 'No Sonix ID provided!',
        severity: 'error',
      });

      return;
    }

    showSnackbar({
      message: 'Translating...',
      severity: 'info',
    });

    setIsTranslationInProgress(true);

    const response = await fetch(`${fetchUrl}/translate/${sonixId}`);
    const results = await response.json();
    if (!results.success) {
      showSnackbar({
        message: results.errorMessage,
        severity: 'error',
      });

      setIsTranslationInProgress(false);
      return;
    }

    showSnackbar({
      message: 'Translation completed!',
      severity: 'success',
    });

    setIsTranslationInProgress(false);

    // console.log(results);
    setKoTranscript(results.transcript.ko);
    setJaTranscript(results.transcript.ja);
  }, [sonixId, showSnackbar]);

  const updateTranslatedTranscriptsOnClickHandler = useCallback(async () => {
    if (!sonixId) {
      showSnackbar({
        message: 'No Sonix ID provided!',
        severity: 'error',
      });
      return;
    }
    showSnackbar({
      message: 'Updating translated transcripts...',
      severity: 'info',
    });

    const response = await fetch(`${fetchUrl}/translate/update/${sonixId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        updatedTranscript: {
          ko: koTranscript,
          ja: jaTranscript,
        },
      }),
    });
    const results = await response.json();

    if (!results.success) {
      showSnackbar({
        message: results.errorMessage,
        severity: 'error',
      });

      return;
    }

    showSnackbar({
      message: 'Translated transcripts updated!',
      severity: 'success',
    });

    // console.log(results);
    setAreTranslatedTranscriptsEdited(false);
  }, [sonixId, koTranscript, jaTranscript, showSnackbar]);

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            Auto Transcription Helper
          </Typography>
        </ExpansionPanelSummary>
        <div>
          <Grid container spacing={4} className={classes.tree} justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Upload File"
                onClick={() => setIsAudioFileUploaderDialogOpen(true)}
                disabled={isTranscriptionInProgress}
              />
              <AudioFileUploader
                open={isAudioFileUploaderDialogOpen}
                setClose={() => setIsAudioFileUploaderDialogOpen(false)}
                youtubeId={youTubeVideoId}
                start={
                  startTime !== undefined
                    ? convertTimeIntoSeconds(startTime)
                    : null
                }
                end={
                  endTime !== undefined
                    ? convertTimeIntoSeconds(endTime, true)
                    : null
                }
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Transcribe"
                onClick={() => transcribeOnClickHandler(false)}
                disabled={isTranscriptionInProgress}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Force Transcribe"
                onClick={() => setIsForceTranscriptionConfirmDialogOpen(true)}
                disabled={isTranscriptionInProgress}
              />
              <Dialog
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={isForceTranscriptionConfirmDialogOpen}
                onClose={() => setIsForceTranscriptionConfirmDialogOpen(false)}
              >
                <DialogTitle id="confirmation-dialog-title">
                  Confirm Force Transcription
                </DialogTitle>
                <DialogContent dividers>
                  Are you sure you want to re-transcribe without checking for
                  existing transcripts? (Use this option if Sonix errors
                  persist.)
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    label="Force Transcribe"
                    style={{ backgroundColor: '#f44336' }}
                    onClick={() => {
                      transcribeOnClickHandler(true);
                      setIsForceTranscriptionConfirmDialogOpen(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    label="Cancel"
                    onClick={() =>
                      setIsForceTranscriptionConfirmDialogOpen(false)
                    }
                  />
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Update English Transcript"
                onClick={updateEnTranscriptOnClickHandler}
                disabled={!sonixPublicUrl}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Sync English Transcript"
                onClick={syncEnTranscriptOnClickHandler}
                disabled={!sonixId}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.tree} justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Translate"
                onClick={translateOnClickHandler}
                disabled={enTranscript === '' || isTranslationInProgress}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Update Translated Transcripts"
                onClick={updateTranslatedTranscriptsOnClickHandler}
                disabled={!areTranslatedTranscriptsEdited}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            className={`${classes.tree}`}
            justify="center"
          >
            <Grid item>
              <div className={classes.labelRoot}>
                <Typography className={classes.categoryLabelText}>
                  Original Transcript
                </Typography>
              </div>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                rowsMax={20}
                placeholder="Transcript will be shown here."
                disabled
                value={enTranscript}
                onChange={(event) => {
                  setEnTranscript(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <div className={classes.labelRoot}>
                <Typography className={classes.categoryLabelText}>
                  Korean Translation
                </Typography>
              </div>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                rowsMax={20}
                placeholder="Transcript will be shown here."
                disabled={koTranscript === ''}
                value={koTranscript}
                onChange={(event) => {
                  setAreTranslatedTranscriptsEdited(true);
                  setKoTranscript(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <div className={classes.labelRoot}>
                <Typography className={classes.categoryLabelText}>
                  Japanese Translation
                </Typography>
              </div>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                rowsMax={20}
                placeholder="Transcript will be shown here."
                disabled={jaTranscript === ''}
                value={jaTranscript}
                onChange={(event) => {
                  setAreTranslatedTranscriptsEdited(true);
                  setJaTranscript(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </ExpansionPanel>
    </div>
  );
};

AutoTranscriptionHelper.propTypes = {
  youTubeVideoId: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

export default AutoTranscriptionHelper;
